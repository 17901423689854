// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gruppenstunden-js": () => import("./../../../src/pages/gruppenstunden.js" /* webpackChunkName: "component---src-pages-gruppenstunden-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sommerlager-js": () => import("./../../../src/pages/sommerlager.js" /* webpackChunkName: "component---src-pages-sommerlager-js" */)
}

